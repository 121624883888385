<template>
    <div class="grid grid-cols-12 gap-4 mt-30">
        <!-- Info message -->
        <div class="col-span-12 mx-30 p-30 mb-30 bg-white rounded-lg shadow">
            <p class="font-4xl opacity-85 font-titillium-bold text-gray-800">{{ $t('createFormInfoMessage') }}</p>
            <!-- Confirmation box for success messages -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <!-- Title -->
        <div class="col-span-12 md:col-span-6 ml-30 mb-22">
            <!-- Show form name -->
            <div
                v-if="showUpdateName"
                class="flex items-center"
            >
                <h1 class="font-4xl opacity-85 font-titillium-bold text-gray-800">{{ form_name }}</h1>
                <img
                    @click="showUpdateName = false"
                    class="ml-10 h-30 w-30"
                    :src="require('@/assets/images/icons/edit.svg')"
                    alt=""
                >
            </div>
            <!-- Show input for updating the form name -->
            <div
                v-else
                class="w-full flex items-center"
            >
                <Input
                    :label="$t('formCreateLabelText')"
                    :placeholder="$t('formCreatePlaceholderText')"
                    type="text"
                    v-model="form_name"
                />
                <img
                    @click="showUpdateName = true"
                    class="ml-10 h-30 w-30 mt-20 mr-30 mr-md-0"
                    :src="require('@/assets/images/icons/edit.svg')"
                    alt=""
                >
            </div>
            <p
                v-if="errors.name"
                class="text-red-500 small mt-4 mb-15"
            >
                {{errors.name[0]}}
            </p>
        </div>
        <!-- Add form section -->
        <div class="col-span-12 md:col-span-6 flex justify-start md:justify-end items-center mb-22 ml-30 ml-md-0 mr-30 relative">
            <!-- Preview button -->
            <router-link
                class="button w-25 flex items-center justify-center text-white bg-purple-500 hover:bg-purple-600"
                :to='{name:"FormPreview"}'
                target="_blank"
            >
                {{ $t('createFormPreview') }}
            </router-link>
        </div>
        <!-- Form builder -->
        <div class="col-span-12 mx-30 p-30 bg-white rounded-lg shadow">
            <!-- Add info row section -->
            <div
                v-if="showAddInfoRow === true"
                class="p-30 bg-gray-100 rounded-lg mt-30"
            >
                <!-- Option buttons for adding info row -->
                <div class="flex items-center justify-end">
                    <!-- Delete row -->
                    <Button
                        class="button bg-red-500 text-white hover:bg-red-600 mr-30"
                        role="button"
                        :text="$t('formBuilderDeleteRowBtnText')"
                        @btnClick="deleteInfoRow"
                    />
                    <!-- Update row -->
                    <Button
                        v-if="updateBtn"
                        class="button bg-purple-500 text-white hover:bg-purple-600"
                        role="button"
                        :text="$t('formBuilderUpdateRowBtnText')"
                        @btnClick="updateRow(rows[idx])"
                    />
                    <!-- Add row -->
                    <Button
                        v-else
                        class="button bg-purple-500 text-white hover:bg-purple-600"
                        role="button"
                        :text="$t('formBuilderSaveRowBtnText')"
                        @btnClick="addRowToForm"
                    />
                </div>
                <!-- From info row -->
                <div class="col-span-12">
                    <FormBuilderInfoRow
                        :subtitle="info.subtitle"
                        :details="info.details"
                        ref="info_column"
                    />
                </div>
            </div>
            <!-- Add info column row section -->
            <div
                v-if="showAddFormColumn === true"
                class="p-30 bg-gray-100 rounded-lg mt-30"
            >
                <!-- Option buttons for adding column row -->
                <div class="flex items-center justify-end">
                    <!-- Delete button -->
                    <Button
                        class="button bg-red-500 text-white hover:bg-red-600 mr-30"
                        role="button"
                        :text="$t('formBuilderDeleteRowBtnText')"
                        @btnClick="deleteRow"
                    />
                    <!-- Update button -->
                    <Button
                        v-if="updateBtn"
                        class="button bg-purple-500 text-white hover:bg-purple-600"
                        role="button"
                        :text="$t('formBuilderUpdateRowBtnText')"
                        @btnClick="updateRow(rows[idx])"
                    />
                    <!-- Add button -->
                    <Button
                        v-else
                        class="button bg-purple-500 text-white hover:bg-purple-600"
                        role="button"
                        :text="$t('formBuilderSaveRowBtnText')"
                        @btnClick="addRowToForm"
                    />

                </div>
                <!-- Form builder show one column -->
                <div
                    v-if="showOneColumn"
                    class="col-span-12"
                >
                    <!-- One column row, column one -->
                    <FormBuilderColumn
                        :type="column_1_1.type"
                        :label="column_1_1.label"
                        :required="column_1_1.required"
                        :placeholder="column_1_1.placeholder"
                        :options="column_1_1.options"
                        ref="column_1_1"
                    />
                </div>
                <!-- Form builder show two columns -->
                <div
                    v-if="showTwoColumn"
                    class="grid lg:grid-cols-2 gap-30"
                >
                    <!-- Two columns row, column one -->
                    <FormBuilderColumn
                        :type="column_2_1.type"
                        :label="column_2_1.label"
                        :required="column_2_1.required"
                        :placeholder="column_2_1.placeholder"
                        :options="column_2_1.options"
                        ref="column_2_1"
                    />
                    <!-- Two columns row, column two -->
                    <FormBuilderColumn
                        :type="column_2_2.type"
                        :label="column_2_2.label"
                        :required="column_2_2.required"
                        :placeholder="column_2_2.placeholder"
                        :options="column_2_2.options"
                        ref="column_2_2"
                    />
                </div>
                <!-- Form builder show three columns -->
                <div
                    v-if="showThreeColumn"
                    class="grid xl:grid-cols-3 gap-30"
                >
                    <!-- Three columns row, column one -->
                    <FormBuilderColumn
                        :type="column_3_1.type"
                        :label="column_3_1.label"
                        :required="column_3_1.required"
                        :placeholder="column_3_1.placeholder"
                        :options="column_3_1.options"
                        ref="column_3_1"
                    />
                    <!-- Three columns row, column two -->
                    <FormBuilderColumn
                        :type="column_3_2.type"
                        :label="column_3_2.label"
                        :required="column_3_2.required"
                        :placeholder="column_3_2.placeholder"
                        :options="column_3_2.options"
                        ref="column_3_2"
                    />
                    <!-- Three columns row, column three -->
                    <FormBuilderColumn
                        :type="column_3_3.type"
                        :label="column_3_3.label"
                        :required="column_3_3.required"
                        :placeholder="column_3_3.placeholder"
                        :options="column_3_3.options"
                        ref="column_3_3"
                    />
                </div>
            </div>
            <!-- Initiate adding info form -->
            <div
                v-if="showAddInfoRow === false"
                class="p-30 bg-gray-100 rounded-lg flex flex-col md:flex-row md:items-center justify-between mt-30"
            >
                <p class="w-full text-lg2 tracking-tight text-gray-800 mb-22 md:mb-0 font-titillium-normal">{{ $t('createFormAddTitleAndSubtitleText') }}</p>
                <Button
                    class="button w-auto md:w-257 md:ml-10 lg:ml-0 bg-purple-500 text-white hover:bg-purple-600"
                    role="button"
                    :disabled="showAddFormColumn === false ? disabledAddNewInfoRowButton === true : disabledAddNewInfoRowButton === false"
                    :text="$t('createFormAddTitleAndSubtitleBtnText')"
                    @btnClick="addInfoRow"
                />
            </div>
            <!-- Initiate adding new form rows columns -->
            <div
                v-if="showAddFormColumn === false"
                class="p-30 bg-gray-100 rounded-lg mt-30 flex flex-col md:flex-row md:items-center justify-between"
            >
                <div class="flex items-center w-full mb-22 md:mb-0">
                    <p class="text-lg2 tracking-tight text-gray-800 font-titillium-normal">{{ $t('createFormAddFieldsText_1') }}</p>
                    <Multiselect
                        class="rounded border border-gray-200 w-85 mx-10"
                        v-model="select_option"
                        :options="select_options"
                        :searchable="false"
                        :show-labels="false"
                        placeholder=""
                    ></Multiselect>
                    <p class="text-lg2 tracking-tight text-gray-800 font-titillium-normal">{{ $t('createFormAddFieldsText_2') }}</p>
                </div>
                <Button
                    class="button w-auto md:w-257 md:ml-10 lg:ml-0 bg-purple-500 text-white hover:bg-purple-600"
                    :disabled="select_option !== null ? this.disabledAddNewRowButton === false : this.disabledAddNewRowButton === true"
                    role="button"
                    :text="$t('createFormAddTitleAndSubtitleBtnText')"
                    @btnClick="selectedOption"
                />
            </div>
        </div>
        <!-- Show form rows -->
        <div class="col-span-12 mx-30">
            <!-- Form builder rows -->
            <div>
                <draggable
                    v-model="rows"
                    group="form"
                    @start="drag=true"
                    @end="drag=false"
                >
                    <div
                        class="bg-white mt-30 p-30 rounded-lg grid lg:grid-cols-2"
                        v-for="(item, index) in rows"
                        :key="index"
                        ref="added_row"
                    >
                        <!-- Display form builder data -->
                        <div class="flex items-center w-full">
                            <img
                                class="mr-20 w-16 h-16"
                                :src="require('@/assets/images/icons/drag.svg')"
                                alt=""
                            >
                            <p v-if="item.type === 'info'">{{item.subtitle}}</p>
                            <p v-if="item.colums_number === 1">{{item.columns[1].label}}</p>
                            <p v-if="item.colums_number === 2">{{item.columns[1].label}}, {{item.columns[2].label}}</p>
                            <p v-if="item.colums_number === 3">{{item.columns[1].label}}, {{item.columns[2].label}}, {{item.columns[3].label}}</p>
                        </div>
                        <!-- Form builder option rows -->
                        <div class="flex lg:justify-end mt-30 lg:mt-0">
                            <!-- Delete form builder row -->
                            <Button
                                class="button bg-red-500 text-white hover:bg-red-600 mr-30 px-22 lg:px-0 w-auto lg:w-257"
                                role="button"
                                :text="$t('formBuilderDeleteRowBtnText')"
                                @btnClick="deleteFormRow(rows.indexOf(item));"
                            />
                            <!-- Send form builder row data to be updated -->
                            <Button
                                class="button bg-purple-500 text-white hover:bg-purple-600 px-22 lg:px-0 w-auto lg:w-257"
                                role="button"
                                :disabled="(showAddInfoRow === true || showAddFormColumn === true) ? disabledEditRowBtn === true : disabledEditRowBtn === false"
                                :text="$t('formBuilderModifyRowBtnText')"
                                @btnClick="sendDataToUpdateRow(item, index)"
                            />
                        </div>
                    </div>
                </draggable>
            </div>
            <!-- Update the form buttons -->
            <div
                class="flex justify-end mt-30"
                v-if="showUpdateForm"
            >
                <!-- Update publish form -->
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600 mr-30"
                    role="button"
                    :text="$t('formBuilderPublishRowBtnText')"
                    @btnClick="publishDraftForm"
                    :disabled="publishDraftFormLoading || rows.length == []"
                    :spinning="publishDraftFormLoading"
                />
                <!-- Update draft form -->
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    role="submit"
                    :text="$t('createFormSave')"
                    @btnClick="updateDraftForm"
                    :disabled="updateDraftFormLoading || rows.length == []"
                    :spinning="updateDraftFormLoading"
                />
            </div>
            <!-- Save the form buttons -->
            <div
                class="flex justify-end mt-30"
                v-else
            >
                <!-- Save published form -->
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600 mr-30"
                    role="button"
                    :text="$t('formBuilderPublishRowBtnText')"
                    @btnClick="publishForm"
                    :disabled="publishFormLoading || disabledSaveFormButtons"
                    :spinning="publishFormLoading"
                />
                <!-- Save draft form -->
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    role="submit"
                    :text="$t('createFormSave')"
                    @btnClick="saveDraftForm"
                    :disabled="saveDraftFormLoading || disabledSaveFormButtons"
                    :spinning="saveDraftFormLoading"
                />
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Button from '@/components/buttons/Button'
import Multiselect from 'vue-multiselect'
import FormBuilderColumn from '@/components/form-builder/FormBuilderColumn'
import FormBuilderInfoRow from '@/components/form-builder/FormBuilderInfoRow'
import SuccessBox from '@/components/success-message/SuccessBox'
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import Input from '@/components/form/Input'

export default {
    name: 'FormCreate',
    props: ['formName', 'slug'],

    components: {
        Button,
        FormBuilderColumn,
        FormBuilderInfoRow,
        Multiselect,
        draggable,
        Input
    },

    data () {
        return {
            disabledEditRowBtn: true,
            disabledAddNewInfoRowButton: false,
            disabledAddNewRowButton: true,
            showUpdateName: true,
            form_name: '',
            select_option: null, // Initial select option
            select_options: ['1', '2', '3'], // Select columns options
            updateBtn: false,
            showUpdateForm: false,
            showAddFormColumn: false, // Show form builder column container
            showOneColumn: false, // Show form builder one column
            showTwoColumn: false, // Show form builder two column
            showThreeColumn: false, // Show form builder three column
            showAddInfoRow: false, // Show form builder add info row
            autosave: true,
            role: '',
            roles: [
                { value: 'ngo-manager', name: 'Manager' },
                { value: 'ngo-staff', name: 'Staff' }],
            rows: [],
            row: null,
            column_1_1: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            column_2_1: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            column_2_2: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            column_3_1: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            column_3_2: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            column_3_3: {
                type: '',
                label: '',
                required: false,
                placeholder: '',
                options: ''
            },
            info: {
                type: 'info',
                subtitle: '',
                details: ''
            },
            idx: null,
            getForm: [],

            saveDraftFormLoading: false,
            publishFormLoading: false,
            publishDraftFormLoading: false,
            updateDraftFormLoading: false,
            disabledSaveFormButtons: true
        }
    },

    computed: {
        /** Vuex getters. */
        ...mapGetters(['token', 'manager', 'userType', 'allForms', 'createStatus', 'updateStatus', 'error', 'errors'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['addForm', 'updateForm', 'getForms']),

        /** Show add form builder based on number of columns selected. */
        selectedOption () {
            /** If selected option show columns. */
            if (this.select_option === '1') {
                this.showAddFormColumn = true
                this.showOneColumn = true
                this.column_1_1 = {}
            }
            if (this.select_option === '2') {
                this.showAddFormColumn = true
                this.showTwoColumn = true
                this.column_2_1 = {}
                this.column_2_2 = {}
            }
            if (this.select_option === '3') {
                this.showAddFormColumn = true
                this.showThreeColumn = true
                this.column_3_1 = {}
                this.column_3_2 = {}
                this.column_3_3 = {}
            }
        },

        /** Show add form builder for adding info row. */
        addInfoRow () {
            this.showAddInfoRow = true
            this.info = {}
        },

        /** Get values for row column one. */
        getOneColumnRowValue () {
            return {
                type: 'columns',
                colums_number: 1,
                columns: {
                    1: this.$refs.column_1_1.getValues()
                }
            }
        },

        /** Get values for row column two. */
        getTwoColumnRowValue () {
            return {
                type: 'columns',
                colums_number: 2,
                columns: {
                    1: this.$refs.column_2_1.getValues(),
                    2: this.$refs.column_2_2.getValues()
                }
            }
        },

        /** Get values for row column three. */
        getThreeColumnRowValue () {
            return {
                type: 'columns',
                colums_number: 3,
                columns: {
                    1: this.$refs.column_3_1.getValues(),
                    2: this.$refs.column_3_2.getValues(),
                    3: this.$refs.column_3_3.getValues()
                }
            }
        },

        /** Get values from all the columns. */
        getColumnRowValue () {
            let values = null

            switch (this.select_option) {
            case '1':
                values = this.getOneColumnRowValue()
                break
            case '2':
                values = this.getTwoColumnRowValue()
                break
            case '3':
                values = this.getThreeColumnRowValue()
                break

            default:
                break
            }

            return values
        },

        /** Get values for info row. */
        getInfoValue () {
            return {
                type: 'info',
                subtitle: this.$refs.info_column.getSubtitleValue(),
                details: this.$refs.info_column.getDetailsValue()
            }
        },

        /** Add new row to form builder. */
        addRowToForm () {
            if (this.showAddFormColumn === true) {
                this.rows.push(this.getColumnRowValue())

                this.showOneColumn = false
                this.showTwoColumn = false
                this.showThreeColumn = false
                this.select_option = null
                this.showAddFormColumn = false
            } else {
                this.rows.push(this.getInfoValue())

                this.disabledSaveFormButtons = false
                this.showAddInfoRow = false
            }
        },

        /** Dispaly data from form builder to be updated. */
        sendDataToUpdateRow (item, index) {
            if (item.colums_number === 1) {
                this.column_1_1.type = item.columns[1].type
                this.column_1_1.label = item.columns[1].label
                this.column_1_1.required = item.columns[1].required === '1'
                this.column_1_1.placeholder = item.columns[1].placeholder
                this.column_1_1.options = item.columns[1].options

                this.showAddFormColumn = true
                this.showOneColumn = true
                this.idx = index
            }

            if (item.colums_number === 2) {
                this.column_2_1.type = item.columns[1].type
                this.column_2_1.label = item.columns[1].label
                this.column_2_1.required = item.columns[1].required === '1'
                this.column_2_1.placeholder = item.columns[1].placeholder
                this.column_2_1.options = item.columns[1].options

                this.column_2_2.type = item.columns[2].type
                this.column_2_2.label = item.columns[2].label
                this.column_2_2.required = item.columns[2].required === '1'
                this.column_2_2.placeholder = item.columns[2].placeholder
                this.column_2_2.options = item.columns[2].options

                this.showAddFormColumn = true
                this.showTwoColumn = true
                this.idx = index
            }

            if (item.colums_number === 3) {
                this.column_3_1.type = item.columns[1].type
                this.column_3_1.label = item.columns[1].label
                this.column_3_1.required = item.columns[1].required === '1'
                this.column_3_1.placeholder = item.columns[1].placeholder
                this.column_3_1.options = item.columns[1].options

                this.column_3_2.type = item.columns[2].type
                this.column_3_2.label = item.columns[2].label
                this.column_3_2.required = item.columns[2].required === '1'
                this.column_3_2.placeholder = item.columns[2].placeholder
                this.column_3_2.options = item.columns[2].options

                this.column_3_3.type = item.columns[2].type
                this.column_3_3.label = item.columns[2].label
                this.column_3_3.required = item.columns[2].required === '1'
                this.column_3_3.placeholder = item.columns[2].placeholder
                this.column_3_3.options = item.columns[2].options

                this.showAddFormColumn = true
                this.showThreeColumn = true
                this.idx = index
            }

            if (item.type === 'info') {
                this.info = item
                this.showAddInfoRow = true
                this.idx = index
            }
            this.updateBtn = true
        },

        /** Delete column row. */
        deleteRow () {
            this.showAddFormColumn = false
            this.showOneColumn = false
            this.showTwoColumn = false
            this.showThreeColumn = false
            this.select_option = null
            this.updateBtn = false
        },

        /** Delete info row. */
        deleteInfoRow () {
            this.showAddInfoRow = false
        },

        /** Delete row from form builder. */
        deleteFormRow (i) {
            this.rows.splice(i, 1)
            this.disabledSaveFormButtons = true
        },

        /** Update form builder row. */
        updateRow (item) {
            if (item.colums_number === 1) {
                item.columns[1] = this.$refs.column_1_1.getValues()
                this.showOneColumn = false
            }

            if (item.colums_number === 2) {
                item.columns[1] = this.$refs.column_2_1.getValues()
                item.columns[2] = this.$refs.column_2_2.getValues()
                this.showTwoColumn = false
            }

            if (item.colums_number === 3) {
                item.columns[1] = this.$refs.column_3_1.getValues()
                item.columns[2] = this.$refs.column_3_2.getValues()
                item.columns[3] = this.$refs.column_3_3.getValues()
                this.showThreeColumn = false
            }

            if (item.type === 'info') {
                item.subtitle = this.$refs.info_column.getSubtitleValue()
                item.details = this.$refs.info_column.getDetailsValue()
                this.showAddInfoRow = false
            }

            this.showAddFormColumn = false
            this.updateBtn = false
        },

        /** Save the form as draft. */
        saveDraftForm () {
            this.saveDraftFormLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.addForm({
                token: this.token,
                status: 'draft',
                name: this.form_name,
                form: JSON.stringify(this.rows)
            }).then((response) => {
                if (this.createStatus === true) {
                    this.saveDraftFormLoading = false
                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('createSuccessMessage'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)
                    this.$router.push({ name: 'FormCreate', params: { slug: `${response.data.id}` } })
                    this.getForms({ token: this.token }).then(response => {
                        /** JSON parse recived forms array and find in array item with id equal to recived slug. */
                        this.getForm = JSON.parse(JSON.stringify(this.allForms)).find((form) => `${form.id}` === this.slug)
                        /** Put form data in rows array. */
                        this.rows = this.getForm.form
                        /** Put form name from array in form name. */
                        this.form_name = this.getForm.name
                    })

                    /** Show update buttons */
                    this.showUpdateForm = true
                } else {
                    this.saveDraftFormLoading = false
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
            })

            /** Disable autosave. */
            this.autosave = false

            /** Clear form data saved in local storage. */
            localStorage.removeItem('form')
            localStorage.removeItem('name')
        },

        /** Save the form as published. */
        publishForm () {
            this.publishFormLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.addForm({
                token: this.token,
                status: 'published',
                name: this.form_name,
                form: JSON.stringify(this.rows)
            }).then(() => {
                if (this.createStatus === true) {
                    this.publishFormLoading = false
                    this.$router.push({ name: 'Forms', params: { showConfirmationBox: true } })
                } else {
                    this.publishFormLoading = false
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }

                /** Disable autosave. */
                this.autosave = false

                /** Clear form data saved in local storage. */
                localStorage.removeItem('form')
                localStorage.removeItem('name')
            })
        },

        /** Update draft form. */
        updateDraftForm () {
            this.updateDraftFormLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.updateForm({
                token: this.token,
                status: 'draft',
                id: this.getForm.id,
                name: this.form_name,
                form: JSON.stringify(this.rows)
            }).then((response) => {
                if (this.createStatus === true) {
                    this.updateDraftFormLoading = false
                    /** Dynamically mount success box to page */
                    const instance = new SuccessBoxComponent({
                        propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('createSuccessMessage'), success: true }
                    })
                    instance.$mount()
                    this.$refs.successBoxContainer.appendChild(instance.$el)
                } else {
                    this.updateDraftFormLoading = false
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }
                /** Clear form data saved in local storage. */
                localStorage.removeItem('form')
                localStorage.removeItem('name')

                /** Disable autosave. */
                this.autosave = false
            })
        },

        /** Update published form. */
        publishDraftForm () {
            this.publishDraftFormLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.updateForm({
                token: this.token,
                status: 'published',
                id: this.getForm.id,
                name: this.form_name,
                form: JSON.stringify(this.rows)
            }).then((response) => {
                if (this.createStatus === true) {
                    this.publishDraftFormLoading = false
                    this.$router.push({ name: 'Forms', params: { showConfirmationBox: true } })
                } else {
                    this.publishDraftFormLoading = false
                    if (this.error !== '') {
                        const instance = new SuccessBoxComponent({
                            propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                        })
                        instance.$mount()
                        this.$refs.successBoxContainer.appendChild(instance.$el)
                    }
                }

                /** Disable autosave. */
                this.autosave = false

                /** Clear form data saved in local storage. */
                localStorage.removeItem('form')
                localStorage.removeItem('name')
            })
        }
    },

    watch: {
        /** Watch for rows array and save it to local storage. */
        rows: {
            handler: function () {
                localStorage.setItem('form', JSON.stringify(this.rows))
            },
            deep: true
        },

        /** Watch for form name and save it to local storage. */
        form_name: {
            handler: function () {
                localStorage.setItem('name', JSON.stringify(this.form_name))
            },
            deep: true
        }
    },

    created () {
        /** Redirect to dashboard if user is not ngo manager. */
        if (this.userType !== this.manager) {
            this.$router.push({ name: 'Dashboard' })
        }

        if (this.$route.params.slug) {
            /** Extract all forms form api. */
            this.getForms({ token: this.token }).then(response => {
                /** JSON parse recived forms array and find in array item with id equal to recived slug. */
                this.getForm = JSON.parse(JSON.stringify(this.allForms)).find((form) => `${form.id}` === this.slug)
                /** Put form data in rows array. */
                this.rows = this.getForm.form
                /** Put form name from array in form name. */
                this.form_name = this.getForm.name
            })

            /** Show update buttons */
            this.showUpdateForm = true
        } else {
            /** If form exists in local storage extract it form there if not create new form. */
            if (localStorage.getItem('form') || localStorage.getItem('name')) {
                if (localStorage.getItem('form')) {
                    this.rows = JSON.parse(localStorage.getItem('form'))
                }
                if (localStorage.getItem('name')) {
                    this.form_name = JSON.parse(localStorage.getItem('name'))
                }
            } else {
                /** From name is equal with form name from modal. */
                this.form_name = this.formName
                /** Show save buttons */
                this.showUpdateForm = false
            }
        }
    },

    beforeDestroy () {
        if (this.autosave) {
            if (this.showUpdateForm) {
                this.updateDraftForm()
            } else {
                this.saveDraftForm()
            }
        } else {
            /** Clear form data saved in local storage. */
            localStorage.removeItem('form')
            localStorage.removeItem('name')
        }
    }
}
</script>

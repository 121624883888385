<template>
    <div class="p-22 md:p-30 mt-30 bg-gray-200 rounded">
        <!-- Field for adding label -->
        <Input
            :error="errors.name"
            :class="[errors.name ? 'mb-4' : 'mb-20']"
            :label="$t('formCreateLabelText')"
            :placeholder="$t('formCreatePlaceholderText')"
            type="text"
            ref="label"
            :value="label"
        />
        <!-- Field for adding input type -->
        <label class="flex">{{$t('formCreateTypeText')}}</label>
        <Multiselect
            class="rounded border"
            @input="selectedType"
            :class="[errors.name ? 'mb-4' : 'border-gray-200 mb-20']"
            :placeholder="$t('formCreateInputTypePlaceholderText')"
            v-model="select_input_type"
            :options="select_input_types"
        />
        <!-- Field for adding placeholder -->
        <Input
            v-if="showInputPlaceholder"
            :error="errors.name"
            :class="[errors.name ? 'mb-4' : 'mb-20']"
            :label="$t('formCreatePlaceholderLabel')"
            :placeholder="$t('formCreatePlaceholderLabelText')"
            type="text"
            ref="placeholder"
            :value="placeholder"
        />
        <!-- Field for adding options -->
        <Input
            v-if="showSelectOptions"
            :error="errors.name"
            :class="[errors.name ? 'mb-4' : 'mb-20']"
            :label="$t('formCreateSelectOptionsLabel')"
            :placeholder="$t('formCreateSelectOptionsPlaceholder')"
            type="text"
            ref="options"
            :value="options"
        />
        <!-- Field for adding if input is required or not -->
        <Checkbox
            :class="[errors.consent ? 'mb-4' : 'mb-37']"
            :error="errors.consent"
            :labelTextStart="$t('formCreateCheckboxLabel')"
            name='required'
            ref="required"
            v-model="requiredData"
        />
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Input from '@/components/form/Input'
import Checkbox from '@/components/form/Checkbox'

export default {
    props: {
        options: {
            type: String
        },
        placeholder: {
            type: String
        },
        label: {
            type: String
        },
        type: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    name: 'FormCreate',
    components: {
        Multiselect,
        Input,
        Checkbox
    },
    data () {
        return {
            select_input_type: this.type,
            select_input_types: ['checkbox', 'input', 'select'],
            showInputPlaceholder: false,
            showSelectOptions: false,
            errors: '',

            requiredData: this.required
        }
    },

    methods: {
        /** Get input values. */
        getValues () {
            let values = null

            switch (this.select_input_type) {
            case 'checkbox':
                values = {
                    type: this.select_input_type,
                    label: this.$refs.label.getValue(),
                    required: this.requiredData,
                    value: false
                }
                break
            case 'input':
                values = {
                    type: this.select_input_type,
                    label: this.$refs.label.getValue(),
                    required: this.requiredData,
                    placeholder: this.$refs.placeholder.getValue(),
                    value: ''
                }
                break
            case 'select':
                values = {
                    type: this.select_input_type,
                    label: this.$refs.label.getValue(),
                    required: this.requiredData,
                    options: this.$refs.options.getValue().replace(/\s/g, '').split(','),
                    value: ''
                }
                break
            default:
                break
            }

            return values
        },

        /** Select input type. */
        selectedType () {
            this.showInputPlaceholder = false
            this.showSelectOptions = false
            if (this.select_input_type === 'input') {
                this.showInputPlaceholder = true
            }
            if (this.select_input_type === 'select') {
                this.showSelectOptions = true
            }
        }
    },
    mounted () {
        this.selectedType()
    }
}
</script>

<template>
    <!-- Created form -->
    <div class="col-span-12 bg-white rounded-lg mt-30 p-30 mx-30 relative">
        <!-- Confirmation box for success messages -->
        <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
        </div>
        <div ref="body">
            <!-- Create form rows -->
            <h1 class="font-4xl font-titillium-bold opacity-85 mb-20">{{ form_name }}</h1>
            <div
                :key="rowIndex"
                v-for="(item, rowIndex) in form"
            >
                <!-- Display row if type 'info' if exists. -->

                <div
                    v-if="item.type === 'info'"
                    class="mb-30"
                >
                    <h1 class="text-xl text-gray-800">{{item.subtitle}}</h1>
                    <p>{{item.details}}</p>
                </div>

                <!-- Display row if type 'columns' if exists. -->
                <div
                    class="grid gap-x-30"
                    :class="[`lg:grid-cols-${item.colums_number}`]"
                >
                    <!-- Create input for selected type -->
                    <div
                        v-for="columnIndex in item.colums_number"
                        :key="columnIndex"
                    >
                        <!-- Display form element of type 'input'. -->
                        <div>
                            <Input
                                v-if="item.columns[columnIndex].type === 'input'"
                                :error="errors.name"
                                :class="[errors.name ? 'mb-4' : 'mb-20']"
                                :label="item.columns[columnIndex].label"
                                :placeholder="item.columns[columnIndex].placeholder"
                                :isRequired="item.columns[columnIndex].required"
                                type="text"
                                :ref="'input' + rowIndex + columnIndex"
                                v-model="item.columns[columnIndex].value"
                            />
                        </div>

                        <!-- Display form element of type 'select'. -->
                        <div v-if="item.columns[columnIndex].type === 'select'">
                            <div class="flex">
                                <label class="flex">{{item.columns[columnIndex].label}}</label>
                                <span
                                    class="text-red-500"
                                    v-if="item.columns[columnIndex].required === true"
                                >*</span>
                            </div>

                            <Multiselect
                                class="rounded border"
                                :class="[errors.name ? 'mb-4' : 'border-gray-200 mb-20']"
                                :options="item.columns[columnIndex].options"
                                :ref="`select${rowIndex}${columnIndex}`"
                                v-model="item.columns[columnIndex].value"
                            />
                        </div>

                        <!-- Display form element of type 'checkbox'. -->
                        <Checkbox
                            v-if="item.columns[columnIndex].type === 'checkbox'"
                            :class="[errors.consent ? 'mb-4' : 'mb-37']"
                            :error="errors.consent"
                            :labelTextStart="item.columns[columnIndex].label"
                            v-model="item.columns[columnIndex].value"
                            :checked="item.columns[columnIndex].value"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Display form 'Save' button. -->
        <div
            class="flex items-center justify-end"
            v-if="showSaveButton"
        >
            <Button
                @click.native="saveFrom"
                class="button bg-purple-500 text-white hover:bg-purple-600 w-auto px-30"
                role="button"
                :text="$t('profileSaveButtonText')"
                :disabled="saveFormLoading"
                :spinning="saveFormLoading"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Input from '@/components/form/Input'
import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/buttons/Button'
import html2pdf from 'html2pdf.js'
import SuccessBox from '@/components/success-message/SuccessBox'
import Checkbox from '@/components/form/Checkbox'

export default {
    props: ['rows', 'slug'],
    components: {
        Input,
        Multiselect,
        Button,
        Checkbox
    },
    data () {
        return {
            inputs: {},
            form_name: '',
            option: '',
            errors: '',
            index: 0,
            form: [],
            getForm: [],
            pdfData: undefined,
            showSaveButton: false,

            saveFormLoading: false
        }
    },

    computed: {
        /** Vuex getters. */
        ...mapGetters(['error', 'token', 'allForms', 'formFile', 'requestStatus'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['getForms', 'File', 'getFormFile', 'updateFormFile', 'saveFormFile']),

        /** Method for transform current page into pdf. */
        async generatePDF () {
            var element = this.$refs.body
            var opt = {
                margin: 1,
                filename: this.form_name,
                image: { type: 'jpeg', quality: 1 },
                // html2canvas: { scale: 2 }
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', precision: 16 }
            }

            this.pdfData = await html2pdf().from(element).set(opt).toPdf().output('datauristring')
        },

        /** Method for saving and updating page as pdf. */
        saveFrom () {
            this.saveFormLoading = true

            const SuccessBoxComponent = Vue.extend(SuccessBox)

            /** Generate the PFD format of the form and save it. */
            this.generatePDF().then(() => {
                if (this.$route.query.type === 'edit') {
                    this.updateFormFile({
                        token: this.token,
                        case_id: this.$route.query.case_id,
                        file_id: this.slug,
                        form_values: JSON.stringify(this.form),
                        form_pdf: JSON.stringify(this.pdfData)
                    }).then(response => {
                        if (this.requestStatus === true) {
                            this.saveFormLoading = false

                            /** Dynamically mount success box to page */
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('updateFormFile'), success: true }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                            this.$router.push({ name: 'CaseDetails', params: { slug: this.$route.query.case_id } })
                        } else {
                            this.saveFormLoading = false

                            if (this.error !== '') {
                                const instance = new SuccessBoxComponent({
                                    propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                                })
                                instance.$mount()
                                this.$refs.successBoxContainer.appendChild(instance.$el)
                            }
                        }
                    })
                } else {
                    this.saveFormFile({
                        token: this.token,
                        case_id: this.$route.query.case_id,
                        form_id: this.slug,
                        form_values: JSON.stringify(this.form),
                        form_pdf: JSON.stringify(this.pdfData)
                    }).then(response => {
                        if (this.requestStatus === true) {
                            /** Dynamically mount success box to page */
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('saveFormFile'), success: true }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                            this.$router.push({ name: 'CaseDetails', params: { slug: this.$route.query.case_id } })
                        } else {
                            if (this.error !== '') {
                                const instance = new SuccessBoxComponent({
                                    propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                                })
                                instance.$mount()
                                this.$refs.successBoxContainer.appendChild(instance.$el)
                            }
                        }
                    })
                }
            })
        }
    },

    created () {
        if (this.$route.params.slug) {
            if (this.$route.query.type === 'edit') {
                this.getFormFile({
                    token: this.token,
                    file_id: this.$route.params.slug,
                    case_id: this.$route.query.case_id
                }).then(response => {
                    this.form = this.formFile.form
                    this.form_name = this.formFile.name
                })
            } else {
                /** Extract all forms form api. */
                this.getForms({ token: this.token }).then(response => {
                    /** JSON parse recived forms array and find in array item with id equal to recived slug. */
                    this.getForm = JSON.parse(JSON.stringify(this.allForms)).find((form) => `${form.id}` === this.slug)
                    /** Put form data in form. */
                    this.form = this.getForm.form
                    /** Put form name from array in form name. */
                    this.form_name = this.getForm.name
                })
            }

            /** Display save button if page is accessed from case details. */
            if (this.$route.query.case_id) {
                this.showSaveButton = true
            }
        } else {
            /** If form exists in local storage extract it form there. */
            if (localStorage.getItem('form') || localStorage.getItem('name')) {
                if (localStorage.getItem('form')) {
                    this.form = JSON.parse(localStorage.getItem('form'))
                }
                if (localStorage.getItem('name')) {
                    this.form_name = JSON.parse(localStorage.getItem('name'))
                }
            }
        }
    }
}
</script>

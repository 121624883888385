<template>
    <div class="p-22 md:p-30 mt-30 bg-gray-200 rounded">
        <!-- Field for adding label -->
        <Input
            :error="errors.name"
            :class="[errors.name ? 'mb-4' : 'mb-20']"
            :label="$t('formCreateSubtitleText')"
            :placeholder="$t('formCreateSubtitlePlaceholderText')"
            type="text"
            ref="subtitle"
            :value="subtitle"
        />

        <Input
            :error="errors.name"
            :class="[errors.name ? 'mb-4' : 'mb-20']"
            :label="$t('formCreateDetailsText')"
            :placeholder="$t('formCreateDetailsPlaceholderText')"
            inputLarge="true"
            type="text"
            ref="details"
            :value="details"
        />
    </div>
</template>

<script>
import Input from '@/components/form/Input'

export default {
    props: ['subtitle', 'details'],
    name: 'FormCreate',
    components: {
        Input
    },
    data () {
        return {
            errors: ''
        }
    },

    methods: {
        getSubtitleValue () {
            return this.$refs.subtitle.getValue()
        },

        getDetailsValue () {
            return this.$refs.details.getValue()
        }
    }
}
</script>
